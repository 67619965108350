.results {
    width: 100%;
}

.results th {
    text-align: left;
}
.results td {
    height: 25px;
}

.status {
    font-size: smaller;
}

.messages{
    height: 250px;
    width: 1000px;
    overflow: auto;
}

.invalidLap {
    background-color: red;
    width: 4px;
    height: 18px;
    display: inline-block;
}

.weather, .raceTime {
    font-size: smaller;
}

.dashboard{
    margin: 10px;
}

.desktop {
    display: block;
}

@media screen and (max-width: 600px) {
    .desktop {
        display: none;
    }

    .messages{
        width: 100%;
        overflow: auto;
    }
}
