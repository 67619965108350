.mobile {
    display: none;
}

.row {
    height: 70px;
    width: 100%;
    background-color: gray;
    margin: 3px;
    display: flex;
}

.position {
    width: 10%;
    background-color: gray;
    color: white;
    text-align: center;
    vertical-align: middle;
    font-size: 18pt;
    margin-top: 5px;
}

.entry {
    width: 100%;
    background-color: black;
    color: white;
    padding-left: 5px;
    padding-top: 5px;
}

.driver {
    font-size: 15pt;
}

.car {
    font-size: 10pt;
    padding-bottom: 4px;
}
.last, .lastInvalid {
    font-size: 10pt;
    color: lightgray;
}

.lastInvalid {
    color: red;
}

.laps {
    width: 20%;
    color: darkred;
    font-size: 12pt;
    text-align: center;
    padding-top: 5px;
    background-color: darkgray;
}

@media screen and (max-width: 600px) {
    .mobile {
        display: block;
    }
}
